<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Hiện thị giáo viên trên web
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn rounded color="primary" class="mb-5" x-small large>
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white" @click="openDiaLogAdd">Add Teacher</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sort</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Ảnh trên web</th>
                    <th scope="col">Màu</th>
                    <th scope="col">Display name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(teacher, i) in teachers">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ teacher.user.name }}
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="teacher.user.profile.avatar"
                          :src="teacher.user.profile.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ teacher.user.profile.color }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ teacher.user.profile.display_name }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'UserEdit',
                                  params: { id: teacher.user_id },
                                }"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-square-edit-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Edit User</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDelete(teacher.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!teachers.length">
                    <tr>
                      <td style="text-align: center" colspan="7">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="diaLogAdd" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Thêm giáo viên hiển thị trên web</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="diaLogAdd = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-col cols="12">
                    <label style="font-size: 16px">Teacher</label>
                    <v-autocomplete
                      v-model="user_id"
                      :items="uses_tags"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label=""
                      item-text="name"
                      item-value="id"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="btnAddTeacherWeb"
              >Save</v-btn
            >
            <v-btn color="blue darken-1" text @click="diaLogAdd = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortLessTest = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(teacher, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ teacher.user.name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="sortTeachers"
              >Update</v-btn
            >
            <v-btn color="blue darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
export default {
  name: "TeacherShowWeb",
  components: {
    loading: Loading,
    draggable,
  },
  data() {
    return {
      is_call_api: false,
      teachers: [],
      dialogSort: false,
      data_draggable: [],
      diaLogAdd: false,
      uses_tags: [],
      user_id: null,
    };
  },
  created() {
    this.getAllTeacher();
    this.getAllTeacherShowWeb();
  },
  methods: {
    async getAllTeacher() {
      let vm = this;
      await ApiService.get("prep-app/user?limit=-1").then(function (response) {
        if (response.status === 200) {
          vm.uses_tags = response.data;
        }
      });
    },
    async getAllTeacherShowWeb() {
      let vm = this;
      await ApiService.get("prep-app/teachers/show-web").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.teachers = response.data;
        }
      });
    },
    openDiaLogAdd() {
      this.user_id = null;
      this.diaLogAdd = true;
    },
    async btnAddTeacherWeb() {
      let vm = this;
      let data = {
        offset: this.teachers.length + 1,
        user_id: this.user_id,
      };
      vm.is_call_api = true;
      await ApiService.post("prep-app/teachers/add-show-web", data).then(
        function (response) {
          if (response.status === 200) {
            vm.getAllTeacherShowWeb();
            vm.diaLogAdd = false;
            vm.is_call_api = false;
          }
        }
      );
    },
    openDialogSort() {
      this.data_draggable = [...this.teachers];
      this.dialogSort = true;
    },
    sortTeachers() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              user_id: item.user_id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/teachers/sort-show-web", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllTeacherShowWeb();
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.dialogSort = false;
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    btnDelete(id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/teachers/deleted-show-web/" + id)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllTeacherShowWeb();
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
